import {React, useEffect, useState} from 'react';
import NavBar from './components/NavBar';
import MarketTable from './components/MarketTable';
import TopCard from './components/TopCard';

function App() {
	const [counter, setCounter] = useState(300)
	const [marketData, setMarketData] = useState([])
	const [hotData, setHotData] = useState([])

	useEffect(() => {
		document.title = 'PolyVol';
	  }, []);

	const fetchNewData = async () => {
		try {
			// 165.227.210.51
		  const response = await fetch("https://polyvol.info:8000/api/markets"); // Replace with your API URL
		  const result = await response.json();
		  console.log(result)
		  setMarketData(result); // Update state with API response
		  setCounter(300); // Reset the counter to 5 minutes
		} catch (error) {
		  console.error("Error fetching data:", error);
		}

		try {
			const response = await fetch("https://polyvol.info:8000/api/top-5-moves"); // Replace with your API URL
			const result = await response.json();
			console.log(result)
			setHotData(result); // Update state with API response
			setCounter(300); // Reset the counter to 5 minutes
		  } catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setCounter((prev) => prev - 1);
		}, 1000);

		if (counter === 0) {
			fetchNewData();
			// window.location.reload();
		}

		return () => clearInterval(interval);
	}, [counter]);

	useEffect(() => {
		fetchNewData();
	}, [])

	return (
		<div className="App">
			<NavBar />

			<div className="container">
				<div className="row">
					<div className="col">
						<div className="d-flex justify-content-between align-items-center mt-4 mb-4">
							<div>
								<h2>What's moving 📈 now on <span className="text-success">Polymarket</span></h2>
							</div>

							<div className="d-flex align-items-center">
								<h6 className="me-5">Active Markets <span className="text-success">{marketData.length}</span></h6>
								<h6>Refresh in <span className="text-success">{Math.floor(counter / 60)}:{String(counter % 60).padStart(2, "0")}</span></h6>
							</div>
						</div>

						<p>🚀 Biggest moves since last check</p>
						<div className="d-flex justify-content-between align-items-center">
							<div className="d-flex align-items-center">
							{
								hotData.map((obj) => (
									<TopCard title={obj.question} percentChange={obj.hot} url={`https://polymarket.com/event/${obj.slg}`} />
								))
							}
							</div>
						</div>
						<MarketTable markets={marketData} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
