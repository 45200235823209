import React from "react";

function NavBar() {
  return (
    <nav className="navbar bg-body-tertiary">
        <div className="container-fluid">
            <a className="navbar-brand" href="#">
            🌋 PolyVol.info
            </a>
        </div>
    </nav>
  );
}

export default NavBar;
