import { useState, useMemo } from 'react'

function MarketTable({ markets, filter }) {
    const [volatilityFilter, setVolatilityFilter] = useState('all');
    const [impFilter, setImpFilter] = useState('all');
    const [limit, setLimit] = useState(50);

    const filteredMarkets = markets.filter((market) => {
        if (volatilityFilter === 'high') return market.volatility > 0.01; // Adjust threshold as needed
        if (volatilityFilter === 'low') return market.volatility < 0.01;

        if (impFilter === 'high' && market.imp <= 0.01) return false;
        if (impFilter === 'low' && market.imp >= 0.01) return false;

        return true; // 'all' case
    });

    // Use memo to only recalculate when necessary, but handle 'All' case for no limit
    const limitedMarkets = useMemo(() => {
        if (limit === 'all') {
            return filteredMarkets; // Return all markets if 'all' is selected
        }
        return filteredMarkets.slice(0, limit); // Slice based on the limit otherwise
    }, [filteredMarkets, limit]);

    return (
        <div style={{ marginTop: "4%" }}>
            <p><i>🔮 Markets sorted by 24 hour volatility</i></p>
            <div className="mb-3 d-flex align-items-center">
                <label className="me-2">Filter by Volatility:</label>
                <select
                    className="form-select w-auto"
                    value={volatilityFilter}
                    onChange={(e) => setVolatilityFilter(e.target.value)}
                >
                    <option selected value="high">High Volatility</option>
                    <option value="low">Low Volatility</option>
                </select>

                <label className="mx-4 me-2">Limit Records:</label>
                <select
                    className="form-select w-auto"
                    value={limit}
                    onChange={(e) => setLimit(e.target.value === 'all' ? 'all' : parseInt(e.target.value))}
                >
                    <option value={50}>50</option>
                    <option value={150}>150</option>
                    <option value={250}>250</option>
                    <option value={350}>350</option>
                    <option value={500}>500</option>
                    <option value="all">All</option> {/* Added 'All' option */}
                </select>

                <label className="mx-4 me-2">Filter by 🚨:</label>
                <select
                    className="form-select w-auto"
                    value={impFilter}
                    onChange={(e) => setImpFilter(e.target.value)}
                >
                    <option value="all">All</option>
                    <option value="high">High 🚨</option>
                    <option value="low">Low 🚨</option>
                </select>
            </div>

            <table className="table table-striped table-hover">
                <thead>
                    <th style={{width: '550px'}} scope="col">Question</th>
                    <th scope="col">Odds</th>
                    <th scope="col">24h Volume</th>
                    <th scope="col">Volatility</th>
                    <th scope="col">🚨</th>
                </thead>
                <tbody>
                    {
                        limitedMarkets.map((obj) => (
                            <tr key={obj.market_slug}>
                                <td><a className="text-success" rel="noreferrer" target='_blank' href={`https://polymarket.com/event/${obj.slg}`}><b>{obj.question}</b></a></td>
                                <td>{Math.round(obj.odds * 100)}%</td>
                                <td>${Math.round(obj.volume)}</td>
                                <td>{obj.volatility}</td>
                                <td>{Math.round(obj.imp)}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default MarketTable;