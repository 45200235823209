import React from 'react'


function TopCard({title, percentChange, url}) {
  return (
    <div className="card me-3" style={{width: "18rem"}}>
        <div className="card-body">
            <h5 className="card-title">{title.slice(0, 20)}...</h5>
            <h6 className="card-subtitle mb-2 mt-2 text-success">{percentChange}% ⬆️</h6>
            <a rel="noreferrer" target='_blank' href={url} className="card-link text-success">View</a>
        </div>
    </div>
  )
}

export default TopCard